import { t } from 'i18next';
import {
  Button,
  Icon,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TableContainer,
  TableSortLabel,
  TablePagination,
  Input,
  Select,
  MenuItem,
} from '@mui/material';
import { useEffect, useState, useRef, useCallback } from 'react';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import moment from 'moment';
import './Waba.css';
import { useSnackbar } from 'notistack';
import ConformationDialogBox from 'src/app/main/common/ConformationDialogBox';
import CreateBroadcast from './CreateBroadcast';
import ViewBroadcastDetailPopUp from './ViewBroadcastDetail';
import CreateTemplate from './CreateTemplate';
import wabaQuery from '../../query/waba';
import { handleWabaApiRequest } from '../../common/common';

const WabaBroadcast = (props) => {
  const [tabValue, setTabValue] = useState(0);
  const [getBroadCastList, setGetBroadCastList] = useState([]);
  const [templateList, setTemplateList] = useState([]);
  const [totalBroadCastList, setTotalBroadCastList] = useState();
  const [page, setPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [searchValue, setSearchValue] = useState('');
  const [openCreateDialogue, setOpenCreateDialogue] = useState(false);
  const [openCreateTemplateDialogue, setOpenCreateTemplateDialogue] = useState(false);
  const [openViewBroadCastDialogue, setOpenViewBroadCastDialogue] = useState(false);
  const [openDeleteTemplateDialogue, setOpenDeleteTemplateDialogue] = useState(false);
  const [waBroadcastId, setWaBroadcastId] = useState();
  const [selectedRowAction, setSelectedRowAction] = useState('action');
  const [selectedRow, setSelectedRow] = useState(null);
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const [refreshForDelete, setRefreshForDelete] = useState(false);
  const [previewMessageDialog, setPreviewMessageDialog] = useState(false);

  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
  };

  const handleBroadCast = useCallback(async () => {
    try {
      const payload = {
        query: wabaQuery.getWABroadCastList,
        variables: {
          pageNumber: page,
          pageSize: rowsPerPage,
          searchName: searchValue,
          ...(tabValue === 1 && { isScheduled: true }),
        },
      };
      const res = await handleWabaApiRequest(payload);
      if (res?.getWABroadCastList?.status === 200) {
        setGetBroadCastList(res?.getWABroadCastList?.data);
        setTotalBroadCastList(res?.getWABroadCastList?.totalCount);
      }
    } catch (error) {
      console.error('An error occurred:', error);
    }
  }, [page, rowsPerPage, tabValue, searchValue]);

  const getTemplateList = useCallback(async () => {
    if (tabValue === 2) {
      try {
        const payload = {
          query: wabaQuery.getWhatsappTemplate,
          variables: {
            searchText: searchValue,
          },
        };
        const res = await handleWabaApiRequest(payload);
        if (res?.getWhatsappTemplate?.status === 200) {
          setTemplateList(res?.getWhatsappTemplate?.data);
        }
      } catch (error) {
        console.error('An error occurred:', error);
      }
    }
  }, [searchValue, tabValue]);

  useEffect(() => {
    handleBroadCast();
  }, [handleBroadCast]);

  useEffect(() => {
    getTemplateList();
  }, [getTemplateList]);

  const cancelDialogue = async () => {
    setOpenCreateDialogue(false);
    handleBroadCast();
  };

  const cancelTemplateDialogue = async () => {
    if (previewMessageDialog) {
      setPreviewMessageDialog(false);
    } else {
      setOpenCreateTemplateDialogue(false);
    }

    // handleBroadCast();
  };

  function handleChangePage(event, value) {
    setPage(value + 1);
  }

  function handleChangeRowsPerPage(event) {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(1);
  }

  function handleSearchEvent(event) {
    setPage(1);
    setSearchValue(event.target.value);
  }

  const handleClearSearch = async () => {
    await setPage(1);
    await setSearchValue('');
  };

  function handleActionDropdownChange(event) {
    setSelectedRowAction(event.target.value);
  }

  const handleCloseDeleteDialog = () => {
    setOpenDeleteTemplateDialogue(false);
    setSelectedRow(null);
  };

  const handleDelete = async () => {
    if (selectedRow) {
      try {
        setRefreshForDelete(true);
        const payload = {
          query: wabaQuery.deleteWhatsappTemplate,
          variables: {
            templateId: selectedRow?.templateId,
          },
        };
        const result = await handleWabaApiRequest(payload);
        setRefreshForDelete(false);
        if (result?.deleteWhatsappTemplate?.status === 200) {
          enqueueSnackbar(result?.deleteWhatsappTemplate?.message, {
            variant: 'success',
            autoHideDuration: 3000,
          });
          handleCloseDeleteDialog();
          getTemplateList();
        } else {
          enqueueSnackbar(result?.deleteWhatsappTemplate?.message, {
            variant: 'error',
            autoHideDuration: 3000,
          });
        }
      } catch (error) {
        console.error('Error deleting custom attribute:', error);
      }
    }
  };

  function EnhancedTableHead() {
    return (
      <TableHead className="bg-grey-50 rounded-md hidden md:table-header-group">
        <TableRow>
          <TableCell className="text-16 font-medium" align="left">
            <TableSortLabel hideSortIcon>
              {t('waba.broadcast.myBroadcast.table.srNo')}
            </TableSortLabel>
          </TableCell>
          <TableCell className="text-16 font-medium" align="left">
            <TableSortLabel hideSortIcon>
              {t('waba.broadcast.myBroadcast.table.name')}
            </TableSortLabel>
          </TableCell>
          <TableCell className="text-16 font-medium" align="left">
            <TableSortLabel hideSortIcon>
              {t('waba.broadcast.myBroadcast.table.scheduled')}
            </TableSortLabel>
          </TableCell>
          <TableCell className="text-16 font-medium" align="left">
            <TableSortLabel hideSortIcon>
              {t('waba.broadcast.myBroadcast.table.recipients')}
            </TableSortLabel>
          </TableCell>
          <TableCell className="text-16 font-medium rounded-tr-md w-192">
            <TableSortLabel hideSortIcon>
              {t('waba.broadcast.myBroadcast.table.status')}
            </TableSortLabel>
          </TableCell>
          <TableCell className="text-16 font-medium rounded-tr-md w-192">
            <TableSortLabel hideSortIcon>
              {t('waba.broadcast.myBroadcast.table.action')}
            </TableSortLabel>
          </TableCell>
        </TableRow>
      </TableHead>
    );
  }

  function EnhancedTableHeadTemplate() {
    return (
      <TableHead className="bg-grey-50 rounded-md hidden md:table-header-group">
        <TableRow>
          <TableCell className="text-16 font-medium" align="left">
            <TableSortLabel hideSortIcon>
              {t('waba.broadcast.myBroadcast.table.srNo')}
            </TableSortLabel>
          </TableCell>
          <TableCell className="text-16 font-medium" align="left">
            <TableSortLabel hideSortIcon>
              {t('waba.broadcast.myBroadcast.table.template.name')}
            </TableSortLabel>
          </TableCell>
          <TableCell className="text-16 font-medium" align="left">
            <TableSortLabel hideSortIcon>
              {t('waba.broadcast.myBroadcast.table.template.category')}
            </TableSortLabel>
          </TableCell>
          <TableCell className="text-16 font-medium" align="left">
            <TableSortLabel hideSortIcon>
              {t('waba.broadcast.myBroadcast.table.template.date')}
            </TableSortLabel>
          </TableCell>
          <TableCell className="text-16 font-medium rounded-tr-md w-192">
            <TableSortLabel hideSortIcon>
              {t('waba.broadcast.myBroadcast.table.action')}
            </TableSortLabel>
          </TableCell>
        </TableRow>
      </TableHead>
    );
  }

  return (
    <>
      <div className="p-20 lg:p-28">
        <div className="bg-white rounded-md">
          <div className="flex items-center justify-between border-b-1 border-solid border-grey-300">
            <Tabs
              value={tabValue}
              className="sm:overflow-hidden overflow-scroll p-0"
              onChange={handleTabChange}
              textColor="secondary"
              indicatorColor="secondary"
              aria-label="secondary tabs example"
            >
              <Tab
                className="md:text-18 text-14 font-medium py-20 px-5 md:px-16 text-black"
                value={0}
                label={t('waba.broadcast.tabs.tab1')}
              />
              <Tab
                className="md:text-18 text-14 font-medium py-20 px-5 md:px-16 text-black"
                value={1}
                label={t('waba.broadcast.tabs.tab2')}
              />
              <Tab
                className="md:text-18 text-14 font-medium py-20 px-5 md:px-16 text-black"
                value={2}
                label={t('waba.broadcast.tabs.tab3')}
              />
            </Tabs>
            <div className=" mx-16">
              <div className="inline-block min-w-360 rounded-8 shadow-none border-1 border-solid border-grey-400 rounded-md relative align-middle">
                <Input
                  placeholder={
                    tabValue === 0 || tabValue === 1
                      ? t('waba.broadcast.myBroadcast.search')
                      : t('appPromotionSlider.search')
                  }
                  className="text-black text-16 h-40  px-12"
                  disableUnderline
                  name="searchText"
                  fullWidth
                  onChange={handleSearchEvent}
                  value={searchValue}
                  inputProps={{
                    'aria-label': 'Search',
                  }}
                />
                <span className="absolute right-8 top-10">
                  {searchValue ? (
                    <Icon
                      color="secondary"
                      className="cursor-pointer text-20"
                      onClick={() => handleClearSearch()}
                    >
                      close
                    </Icon>
                  ) : (
                    <Icon color="secondary" className="text-20">
                      search
                    </Icon>
                  )}
                </span>
              </div>
              {tabValue !== 2 && (
                <Button
                  onClick={() => setOpenCreateDialogue(true)}
                  className="rounded-md md:px-40 text-14 font-medium inline-block align-middle ml-36"
                  variant="contained"
                  color="secondary"
                >
                  {t('waba.broadcast.myBroadcast.newBroadcast')}
                </Button>
              )}
              {tabValue === 2 && (
                <Button
                  onClick={() => setOpenCreateTemplateDialogue(true)}
                  className="rounded-md md:px-40 text-14 font-medium inline-block align-middle ml-36"
                  variant="contained"
                  color="secondary"
                >
                  {t('waba.broadcast.AddTemplate.add')}
                </Button>
              )}
            </div>
          </div>
          <div>
            <div className="p-16 broadcast-height w-full">
              <div
                className={`${
                  tabValue !== 0 && tabValue !== 1 ? 'hidden' : ''
                } flex justify-center items-center`}
              >
                {getBroadCastList && getBroadCastList?.length > 0 ? (
                  <div className="w-full">
                    <TableContainer className="border border-solid border-grey-300 rounded-md">
                      <Table className="w-full" aria-labelledby="tableTitle" size="medium">
                        <EnhancedTableHead />
                        <TableBody className="block md:table-row-group">
                          {getBroadCastList?.map((row, index) => {
                            return (
                              <TableRow
                                hover
                                className="border border-t-2 border-t-grey-100 block md:table-row"
                                role="checkbox"
                                tabIndex={-1}
                                key={row.id}
                              >
                                <TableCell
                                  className="text-14 color-black font-semibold block md:table-cell md:w-60 py-5 pt-16 md:py-16"
                                  align="left"
                                >
                                  {(page - 1) * rowsPerPage + index + 1}
                                </TableCell>
                                <TableCell
                                  className="text-14 color-black font-semibold block md:table-cell md:max-w-256 py-5 md:py-16"
                                  align="left"
                                >
                                  {row?.name}
                                </TableCell>
                                <TableCell
                                  className="text-14 color-black font-semibold block md:table-cell md:max-w-136 py-5 md:py-16"
                                  align="left"
                                >
                                  <span className="inline-block align-middle md:hidden mr-10 font-bold text-darkgreen">
                                    {t('users.deactivatedAccount.labels.deactivatedDate')}:
                                  </span>
                                  {row?.scheduledDate
                                    ? moment(row?.scheduledDate).format('MMM DD, YYYY hh:MM A')
                                    : '-'}
                                </TableCell>

                                <TableCell
                                  className="text-14 color-black font-semibold block md:table-cell md:w-200 py-5 md:py-16"
                                  align="left"
                                >
                                  {row?.recipientsCount}{' '}
                                  {t('waba.broadcast.myBroadcast.table.contact')}
                                </TableCell>
                                <TableCell
                                  className="text-14 color-black font-semibold block md:table-cell py-5 pb-16 md:py-16 capitalize"
                                  align="left"
                                >
                                  <span
                                    className={`${
                                      row?.status === 'delivered'
                                        ? 'text-green-A300'
                                        : 'text-yellow-800'
                                    }`}
                                  >
                                    {row?.status}
                                  </span>
                                </TableCell>
                                <TableCell
                                  className="text-14 color-black font-semibold block md:table-cell py-5 pb-16 md:py-16"
                                  align="left"
                                >
                                  <Button
                                    variant="contained"
                                    onClick={() => {
                                      setOpenViewBroadCastDialogue(true);
                                      setWaBroadcastId(row?.id);
                                    }}
                                    className="bg-darkgreen-100 rounded text-darkgreen py-0 min-h-28 h-28 px-12 text-14 font-semibold cursor-pointer hover:bg-darkgreen hover:text-white"
                                  >
                                    {t('waba.broadcast.myBroadcast.table.view')}
                                  </Button>
                                </TableCell>
                              </TableRow>
                            );
                          })}
                        </TableBody>
                      </Table>
                    </TableContainer>

                    <TablePagination
                      sx={{
                        '.MuiInputBase-root': {
                          lineHeight: 'inherit',
                          padding: 0,
                        },
                      }}
                      component="div"
                      count={totalBroadCastList}
                      rowsPerPage={rowsPerPage}
                      page={page - 1}
                      backIconButtonProps={{
                        'aria-label': 'Previous Page',
                      }}
                      nextIconButtonProps={{
                        'aria-label': 'Next Page',
                      }}
                      onPageChange={handleChangePage}
                      onRowsPerPageChange={handleChangeRowsPerPage}
                    />
                  </div>
                ) : (
                  <div className="text-center h-full flex items-center block  broadcast-height">
                    <div className="mx-auto">
                      {searchValue ? (
                        <>
                          <img
                            src="/assets/images/business/mybroadcast.png"
                            className="img-fluid w-full mx-auto max-w-144"
                            alt="..."
                          />
                          <Typography className="text-24 font-semibold mt-36">
                            {t('waba.inbox.noResult')}
                          </Typography>
                        </>
                      ) : (
                        <>
                          <img
                            src="/assets/images/business/mybroadcast.png"
                            className="img-fluid w-full mx-auto max-w-144"
                            alt="..."
                          />
                          <Typography className="text-24 font-semibold mt-36">
                            {t('waba.broadcast.myBroadcast.noBroadcast')}
                          </Typography>
                          <Typography className="text-16 font-medium max-w-640 mt-16 mb-20">
                            {t('waba.broadcast.myBroadcast.noBroadcastDesc')}
                          </Typography>
                          <Button
                            onClick={() => setOpenCreateDialogue(true)}
                            className="rounded-md md:px-40 text-16 font-semibold"
                            variant="contained"
                            color="secondary"
                          >
                            {t('waba.broadcast.myBroadcast.newBroadcast')}
                          </Button>
                        </>
                      )}
                    </div>
                  </div>
                )}
              </div>
              <div className={tabValue !== 2 ? 'hidden' : ''}>
                {templateList && templateList?.length > 0 ? (
                  <div className="w-full">
                    <TableContainer className="border border-solid border-grey-300 rounded-md">
                      <Table className="w-full" aria-labelledby="tableTitle" size="medium">
                        <EnhancedTableHeadTemplate />
                        <TableBody className="block md:table-row-group">
                          {templateList?.map((row, index) => {
                            return (
                              <TableRow
                                hover
                                className="border border-t-2 border-t-grey-100 block md:table-row"
                                role="checkbox"
                                tabIndex={-1}
                                key={row.id}
                              >
                                <TableCell
                                  className="text-14 color-black font-semibold block md:table-cell md:w-60 py-5 pt-16 md:py-16"
                                  align="left"
                                >
                                  {(page - 1) * rowsPerPage + index + 1}
                                </TableCell>
                                <TableCell
                                  className="text-14 color-black font-semibold block md:table-cell md:max-w-256 py-5 md:py-16"
                                  align="left"
                                >
                                  {row?.displayName}
                                </TableCell>

                                <TableCell
                                  className="text-14 color-black font-semibold block md:table-cell py-5 pb-16 md:py-16 capitalize"
                                  align="left"
                                >
                                  {row?.categoryName}
                                </TableCell>
                                <TableCell
                                  className="text-14 color-black font-semibold block md:table-cell md:max-w-136 py-5 md:py-16"
                                  align="left"
                                >
                                  <span className="inline-block align-middle md:hidden mr-10 font-bold text-darkgreen">
                                    {t('users.deactivatedAccount.labels.deactivatedDate')}:
                                  </span>
                                  {row?.cts ? moment(row?.cts).format('MMM DD, YYYY') : '-'}
                                </TableCell>
                                <TableCell
                                  className="text-14 color-black font-semibold block md:table-cell py-5 pb-16 md:py-16"
                                  align="left"
                                >
                                  {/* <Button
                                    variant="contained"
                                    onClick={() => {
                                      setOpenViewBroadCastDialogue(true);
                                      setWaBroadcastId(row?.id);
                                    }}
                                    className="bg-darkgreen-100 rounded text-darkgreen py-0 min-h-28 h-28 px-12 text-14 font-semibold cursor-pointer hover:bg-darkgreen hover:text-white"
                                  >
                                    {t('waba.broadcast.myBroadcast.table.view')}
                                  </Button> */}
                                  <Select
                                    className="rounded-md border border-grey-100 font-semibold text-grey-600 h-28 bg-grey-100 text-16 w-96 min-h-0 p-0 leading-4 shadow-none pt-0"
                                    sx={{ '& .MuiFilledInput-input': { pt: '14px' } }}
                                    variant="filled"
                                    value={selectedRow === row ? selectedRowAction : 'action'}
                                    fullWidth
                                    onChange={handleActionDropdownChange}
                                  >
                                    <MenuItem
                                      value="action"
                                      className="capitalize mx-5 rounded-md"
                                      disabled
                                    >
                                      {t('business.businessList.column.action')}{' '}
                                    </MenuItem>
                                    <MenuItem
                                      value="view"
                                      className="capitalize hover:bg-darkgreen-100 hover:text-darkgreen mx-5 rounded-md p-6"
                                      onClick={() => {
                                        setPreviewMessageDialog(true);
                                        setSelectedRow(row);
                                      }}
                                    >
                                      {t('waba.broadcast.contactList.view')}
                                    </MenuItem>
                                    <MenuItem
                                      value="delete"
                                      className="capitalize hover:bg-darkgreen-100 hover:text-darkgreen mx-5 rounded-md p-6"
                                      onClick={() => {
                                        setSelectedRow(row);
                                        setOpenDeleteTemplateDialogue(true);
                                      }}
                                    >
                                      {t('waba.broadcast.contactList.delete')}
                                    </MenuItem>
                                  </Select>
                                </TableCell>
                              </TableRow>
                            );
                          })}
                        </TableBody>
                      </Table>
                    </TableContainer>
                  </div>
                ) : (
                  <div className="text-center h-full flex items-center block broadcast-height">
                    <div className="mx-auto">
                      {searchValue ? (
                        <>
                          <img
                            src="/assets/images/business/note.png"
                            className="img-fluid w-full mx-auto max-w-144"
                            alt="..."
                          />
                          <Typography className="text-24 font-semibold mt-36">
                            {t('waba.inbox.noResult')}
                          </Typography>
                        </>
                      ) : (
                        <>
                          <img
                            src="/assets/images/business/note.png"
                            className="img-fluid w-full mx-auto max-w-144"
                            alt="..."
                          />
                          <Typography className="text-24 font-semibold mt-36">
                            {t('waba.broadcast.myBroadcast.noTemplate')}
                          </Typography>
                          <Typography className="text-16 font-medium max-w-640 mt-16 mb-20">
                            {t('waba.broadcast.myBroadcast.noTemplateDesc')}
                          </Typography>
                          <Button
                            onClick={() => setOpenCreateTemplateDialogue(true)}
                            className="rounded-md md:px-40 text-16 font-semibold inline-block align-middle"
                            variant="contained"
                            color="secondary"
                          >
                            {t('waba.broadcast.AddTemplate.add')}
                          </Button>
                        </>
                      )}
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
        {openCreateDialogue && (
          <CreateBroadcast
            openValue={openCreateDialogue}
            cancelCall={cancelDialogue}
            popUpMessage={t('waba.broadcast.newBroadcast.title')}
            popUpButtonName={t('waba.broadcast.newBroadcast.add')}
          />
        )}
        {openCreateTemplateDialogue && (
          <CreateTemplate
            openValue={openCreateTemplateDialogue}
            cancelCall={cancelTemplateDialogue}
            popUpMessage={t('waba.broadcast.AddTemplate.add')}
            popUpButtonName={t('waba.broadcast.AddTemplate.create')}
          />
        )}
        {openViewBroadCastDialogue && (
          <ViewBroadcastDetailPopUp
            openValue={openViewBroadCastDialogue}
            cancelCall={() => setOpenViewBroadCastDialogue(false)}
            waBroadcastId={waBroadcastId}
            popUpMessage={t('waba.broadcast.myBroadcast.view.title')}
          />
        )}
        {previewMessageDialog && (
          <CreateTemplate
            openValue={previewMessageDialog}
            cancelCall={cancelTemplateDialogue}
            dialogType="viewTemplate"
            templateId={selectedRow?.id}
            popUpMessage={t('waba.broadcast.myBroadcast.view.view')}
          />
        )}
        {openDeleteTemplateDialogue && (
          <ConformationDialogBox
            open={openDeleteTemplateDialogue}
            onClose={handleCloseDeleteDialog}
            refresh={refreshForDelete}
            onDelete={() => {
              handleDelete();
            }}
            confirmationText={t('waba.broadcast.myBroadcast.confirm')}
            deleteImage="/assets/images/business/warning_alert.png"
            additionalInfo={t('waba.broadcast.AddTemplate.deleteTemplateDesc')}
          />
        )}
      </div>
    </>
  );
};

export default WabaBroadcast;
