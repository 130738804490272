import CryptoJS from 'crypto-js';
import axios from 'axios';
import Icon from '@mui/material/Icon';
import { PhoneNumberUtil } from 'google-libphonenumber';
import history from '@history';
import moment from 'moment';
import userService from '../../services/userService';

const phoneUtil = PhoneNumberUtil.getInstance();

const baseURL = process.env.REACT_APP_API_ENDPOINT;
const instance = axios.create({
  baseURL,
});

const forAuthBaseURL = process.env.REACT_APP_AUTH_ENDPOINT;
const instanceForAuth = axios.create({
  forAuthBaseURL,
});

export const countryData = (countryId = '', phoneCountry = '') => {
  const country = [
    {
      id: 2,
      name: 'India',
      countryCode: 91,
      isoCode: 'IND',
      isActive: true,
      currency: 'INR',
      symbol: '₹',
      flag: 'https://flagcdn.com/w320/in.png',
      phoneCountry: 'in',
    },
    {
      id: 1,
      name: 'United States',
      countryCode: 1,
      isoCode: 'USA',
      isActive: true,
      currency: 'USD',
      symbol: '$',
      flag: 'https://flagcdn.com/w320/us.png',
      phoneCountry: 'us',
    },
  ];
  if (countryId) {
    return country.find((element) => {
      return element.id === countryId;
    });
  }
  if (phoneCountry) {
    return country.find((element) => {
      return element.phoneCountry === phoneCountry;
    });
  }
  return country;
};

export const getParamsId = () => {
  const url = window.location.href;
  const parts = url.split('/');
  const lastPart = parts[parts.length - 1];
  if (/^\d+$/.test(lastPart)) {
    return lastPart;
  }
  return '';
};

export const getEncryptedData = (value = '') => {
  try {
    const publicKeyValue = process.env.REACT_APP_SECRET_KEY;
    return CryptoJS.AES.encrypt(value, publicKeyValue).toString();
  } catch (error) {
    throw new Error(error);
  }
};

export const getDecryptData = (value = '') => {
  try {
    const publicKeyValue = process.env.REACT_APP_SECRET_KEY;
    const decrypted = CryptoJS.AES.decrypt(value, publicKeyValue);
    return decrypted.toString(CryptoJS.enc.Utf8);
  } catch (error) {
    throw new Error(error);
  }
};

export const handleApiRequest = async (data, token = '') => {
  instance.defaults.headers.common.Authorization = `Bearer ${
    token || userService.getAccessToken()
  }`;
  try {
    const response = await instance.post(process.env.REACT_APP_API_OTHER_ENDPOINT, { ...data });
    return await response?.data?.data;
  } catch (error) {
    return error.response
      ? error.response.data
      : { status: 'fail', message: 'Something went wrong' };
  }
};

export const getDataToServer = async (data) => {
  instance.defaults.headers.common.Authorization = `Bearer ${userService.getAccessToken() || null}`;
  try {
    const response = await instance.post(process.env.REACT_APP_API_ENDPOINT, { ...data });
    return await response.data.data;
  } catch (error) {
    return error.response
      ? error.response.data
      : { status: 'fail', message: 'Something went wrong' };
  }
};

export const getDataToServerForAuth = async (data) => {
  instanceForAuth.defaults.headers.common.Authorization = `Bearer ${
    userService.getAccessToken() || null
  }`;
  try {
    const response = await instanceForAuth.post(process.env.REACT_APP_AUTH_ENDPOINT, { ...data });
    return await response.data.data;
  } catch (error) {
    return error.response
      ? error.response.data
      : { status: 'fail', message: 'Something went wrong' };
  }
};

export const handleWabaApiRequest = async (data, token = '') => {
  instance.defaults.headers.common.Authorization = `Bearer ${
    token || userService.getAccessToken()
  }`;
  try {
    const response = await instance.post(process.env.REACT_APP_WABA_API_ENDPOINT, { ...data });
    return await response?.data?.data;
  } catch (error) {
    return error.response
      ? error.response.data
      : { status: 'fail', message: 'Something went wrong' };
  }
};

export const wabaImageSendToServer = async (data) => {
  instance.defaults.headers.common.Authorization = `Bearer ${userService.getAccessToken() || null}`;
  let headerData = {};
  const formData = new FormData();
  let isFile = false;
  if (data?.variables?.templateImage) {
    isFile = true;
    headerData = {
      headers: {
        'apollo-require-preflight': 'true',
        'Content-Type': 'multipart/form-data',
      },
    };
    formData.append('operations', JSON.stringify(data));
    formData.append('map', `{"0":["variables.templateImage"]}`);
    formData.append(0, data?.variables?.templateImage);
  }

  try {
    const response = await instance.post(
      process.env.REACT_APP_WABA_API_ENDPOINT,
      !isFile ? { ...data } : formData,
      headerData
    );
    return response.data.data;
  } catch (error) {
    return error.response
      ? error.response.data
      : { status: 'fail', message: 'Something went wrong' };
  }
};

export const imageDataToServer = async (data) => {
  instance.defaults.headers.common.Authorization = `Bearer ${userService.getAccessToken() || null}`;
  let headerData = {};
  const formData = new FormData();
  let isFile = false;

  if (data?.variables?.icon) {
    isFile = true;
    headerData = {
      headers: {
        'apollo-require-preflight': 'true',
        'Content-Type': 'multipart/form-data',
      },
    };
    formData.append('operations', JSON.stringify(data));
    formData.append('map', `{"0":["variables.icon"]}`);
    formData.append(0, data?.variables?.icon);
  }

  try {
    const response = await instance.post(
      process.env.REACT_APP_API_ENDPOINT,
      !isFile ? { ...data } : formData,
      headerData
    );
    return response.data.data;
  } catch (error) {
    return error.response
      ? error.response.data
      : { status: 'fail', message: 'Something went wrong' };
  }
};

export const imageDataToSend = async (data) => {
  instance.defaults.headers.common.Authorization = `Bearer ${userService.getAccessToken() || null}`;
  let headerData = {};
  const formData = new FormData();
  let isFile = false;
  if (data?.variables?.profileImage) {
    isFile = true;
    headerData = {
      headers: {
        'apollo-require-preflight': 'true',
        'Content-Type': 'multipart/form-data',
      },
    };
    formData.append('operations', JSON.stringify(data));
    formData.append('map', `{"0":["variables.profileImage"]}`);
    formData.append(0, data?.variables?.profileImage);
  }

  try {
    const response = await instance.post(
      process.env.REACT_APP_API_ENDPOINT,
      !isFile ? { ...data } : formData,
      headerData
    );
    return response.data.data;
  } catch (error) {
    return error.response
      ? error.response.data
      : { status: 'fail', message: 'Something went wrong' };
  }
};

export const storeCsvFileDataToServer = async (data) => {
  instance.defaults.headers.common.Authorization = `Bearer ${userService.getAccessToken() || null}`;
  let headerData = {};
  const formData = new FormData();
  let isFile = false;

  if (data?.variables?.file) {
    isFile = true;
    headerData = {
      headers: {
        'apollo-require-preflight': 'true',
        'Content-Type': 'multipart/form-data',
      },
    };
    formData.append('operations', JSON.stringify(data));
    formData.append('map', `{"0":["variables.file"]}`);
    formData.append(0, data?.variables?.file);
  }

  try {
    const response = await instance.post(
      process.env.REACT_APP_API_ENDPOINT,
      !isFile ? { ...data } : formData,
      headerData
    );
    return response.data.data;
  } catch (error) {
    return error.response
      ? error.response.data
      : { status: 'fail', message: 'Something went wrong' };
  }
};

export const serverApiRequest = async (endpoint, data, additionalHeaders = {}) => {
  instance.defaults.headers.common.Authorization = `Bearer ${userService.getAccessToken() || null}`;
  instance.defaults.headers.common.isrequestfrom = true;

  try {
    const response = await instance.post(endpoint, data, { headers: additionalHeaders });
    return response.data?.data;
  } catch (error) {
    return error.response
      ? error.response.data
      : { status: 'fail', message: 'Something went wrong' };
  }
};

export const handleAuthApi = async (data) => {
  instance.defaults.headers.common.Authorization = `Bearer ${userService.getAccessToken() || null}`;
  try {
    const response = await instance.post(process.env.REACT_APP_AUTH_ENDPOINT, { ...data });
    return await response?.data?.data;
  } catch (error) {
    return error.response
      ? error.response.data
      : { status: 'fail', message: 'Something went wrong' };
  }
};

export const imageSendToServer = async (data) => {
  instance.defaults.headers.common.Authorization = `Bearer ${userService.getAccessToken() || null}`;
  let headerData = {};
  const formData = new FormData();
  let isFile = false;
  if (data?.variables?.image) {
    isFile = true;
    headerData = {
      headers: {
        'apollo-require-preflight': 'true',
        'Content-Type': 'multipart/form-data',
      },
    };
    formData.append('operations', JSON.stringify(data));
    formData.append('map', `{"0":["variables.image"]}`);
    formData.append(0, data?.variables?.image);
  }

  try {
    const response = await instance.post(
      process.env.REACT_APP_API_ENDPOINT,
      !isFile ? { ...data } : formData,
      headerData
    );
    return response.data.data;
  } catch (error) {
    return error.response
      ? error.response.data
      : { status: 'fail', message: 'Something went wrong' };
  }
};

export const avatarDataSendToServer = async (data) => {
  instance.defaults.headers.common.Authorization = `Bearer ${userService.getAccessToken() || null}`;
  let headerData = {};
  const formData = new FormData();
  let isFile = false;
  if (data?.variables?.avatarImageFile) {
    isFile = true;
    headerData = {
      headers: {
        'apollo-require-preflight': 'true',
        'Content-Type': 'multipart/form-data',
      },
    };
    formData.append('operations', JSON.stringify(data));
    formData.append('map', `{"0":["variables.avatarImageFile"]}`);
    formData.append(0, data?.variables?.avatarImageFile);
  }

  try {
    const response = await instance.post(
      process.env.REACT_APP_API_ENDPOINT,
      !isFile ? { ...data } : formData,
      headerData
    );
    return response.data.data;
  } catch (error) {
    return error.response
      ? error.response.data
      : { status: 'fail', message: 'Something went wrong' };
  }
};

export const Message = {
  emailRegex: /\S+@\S+\.\S+/,
};

export const reviewFormate = (reviewData, text = 16) => {
  return [...Array(5)].map((_, i) => {
    if (i + 1 <= reviewData) {
      return (
        <Icon className={`text-${text} align-middle mr-2 text-yellow-800`} key={i}>
          star
        </Icon>
      );
    }

    if (reviewData - i > 0 && reviewData - i < 1) {
      return (
        <>
          <Icon className={`text-${text} align-middle mr-2 text-yellow-800`} key={i}>
            star_half
          </Icon>
        </>
      );
    }

    return (
      <Icon className={`text-${text} align-middle mr-2 text-yellow-800 `} key={i}>
        star_outlined
      </Icon>
    );
  });
};

export const sortOptions = (inputValues, options) => {
  if (!inputValues) return options;

  const normalizedInputValue = inputValues.toLowerCase();

  return options.sort((a, b) => {
    const aValue = a.label.toLowerCase();
    const bValue = b.label.toLowerCase();

    if (aValue === normalizedInputValue) return -1;
    if (bValue === normalizedInputValue) return 1;

    if (aValue.startsWith(normalizedInputValue) && !bValue.startsWith(normalizedInputValue))
      return -1;
    if (!aValue.startsWith(normalizedInputValue) && bValue.startsWith(normalizedInputValue))
      return 1;

    return aValue.localeCompare(bValue);
  });
};

export const capitalizeFirstLetter = (string) => {
  if (!string) return '';
  return string.charAt(0).toUpperCase() + string.slice(1);
};

export const convertTemplateToHtml = (template) => {
  let htmlContents = '';

  let bodyText = template || '';

  bodyText = bodyText
    .replace(/\*(.*?)\*/g, '<strong>$1</strong>')
    .replace(/_(.*?)_/g, '<em>$1</em>')
    .replace(/~(.*?)~/g, '<del>$1</del>')
    .replace(/```(.*?)```/g, '<code>$1</code>')
    .replace(/\n/g, '<br>');
  htmlContents += `<div className="block">${bodyText}</div>`;

  return htmlContents;
};

export const handlePhoneNumberValidationCheck = (number, isoCode) => {
  const phoneWithoutDialCode = number?.slice(isoCode?.dialCode?.length);

  if (phoneWithoutDialCode?.length === 0) {
    return true;
  }

  if (/^\d+$/.test(phoneWithoutDialCode)) {
    try {
      const parsedNumber = phoneUtil.parse(phoneWithoutDialCode, isoCode?.countryCode);
      const isValid = phoneUtil.isValidNumberForRegion(parsedNumber, isoCode?.countryCode);

      if (isValid === null || isValid === false) {
        return false;
      }

      return true;
    } catch (error) {
      console.log(error);
      return false;
    }
  }
  return false;
};

export const duration = [
  {
    id: 0,
    value: '1 Year',
    interval: 'year',
  },
  {
    id: 1,
    value: '6 Month',
    interval: 'six_month',
  },
  {
    id: 2,
    value: '3 Month',
    interval: 'three_month',
  },
  {
    id: 3,
    value: '1 Month',
    interval: 'month',
  },
];

export const findExpireDay = (expiryDate) => {
  const now = moment(new Date()).startOf('day');
  const expiry = moment(expiryDate);
  const data = expiry.diff(now, 'days');
  return data;
};

export const handleClick = (index, id) => {
  if (index === 'customAttributes') {
    if (id) {
      history.push(`/customAttributes/${id}`);
    } else {
      history.push('/customAttributes');
    }
  }
  if (index === 'scanQRCode') {
    if (id) {
      history.push(`/qrCode/${id}`);
    } else {
      history.push('/qrCode');
    }
  }
  if (index === 'inviteReviews') {
    if (id) {
      history.push(`/invite-reviews/${id}`);
    } else {
      history.push('/invite-reviews');
    }
  }
  if (index === 'addStaffMember') {
    if (id) {
      history.push(`/staff/${id}`);
    } else {
      history.push('/staff');
    }
  }
  if (index === 'addProductServices') {
    if (id) {
      history.push(`/businessProductAndService/${id}`);
    } else {
      history.push('/businessProductAndService');
    }
  }
  if (index === 'exploreMetrics') {
    if (id) {
      history.push(`/matrices/${id}`);
    } else {
      history.push('/matrices');
    }
  }
  if (index === 'tweakTextComplexity') {
    if (id) {
      history.push(`/autoGenerateText/${id}`);
    } else {
      history.push('/autoGenerateText');
    }
  }
  if (index === 'addMorePlatforms') {
    if (id) {
      history.push(`/manageReviewSite/${id}`);
    } else {
      history.push('/manageReviewSite');
    }
  }
};
