import { Typography } from '@mui/material';
import { useCallback, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { capitalizeFirstLetter, handleApiRequest } from 'src/app/main/common/common';
import FuseLoading from '@fuse/core/FuseLoading';
import { t } from 'i18next';
import { useSnackbar } from 'notistack';
import manageBusinessData from '../../../query/manageBusiness';
import { selectDashboardData } from '../../../../store/userSlice';
import Pagination from '../../../../component/table/Pagination';

const ScanHistory = () => {
  const [loading, setLoading] = useState(true);
  const userCurrentBusinessDetails = useSelector(selectDashboardData);
  const [gridHistoryData, setGridHistoryData] = useState([]);
  const [page, setPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(12);
  const [totalCount, setTotalCount] = useState();
  const { enqueueSnackbar } = useSnackbar();

  const fetchScanHistory = useCallback(async () => {
    if (userCurrentBusinessDetails?.id) {
      setLoading(true);
      const obj = {
        query: manageBusinessData.getUserBusinessRankerHistory,
        variables: {
          userBusinessId: parseInt(userCurrentBusinessDetails?.id, 10),
          pageNumber: page,
          pageSize: rowsPerPage,
        },
      };
      try {
        const result = await handleApiRequest(obj);
        setLoading(false);
        if (result?.getUserBusinessRankerHistory?.status === 200) {
          setGridHistoryData(result?.getUserBusinessRankerHistory?.data);
          setTotalCount(result?.getUserBusinessRankerHistory?.totalCount);
        } else {
          enqueueSnackbar(result?.getUserBusinessRankerHistory?.message || 'Failed to fetch data', {
            variant: 'error',
            autoHideDuration: 2000,
          });
        }
      } catch (error) {
        setLoading(false);
        console.error('Error fetching scan history:', error);
      }
    }
  }, [page, rowsPerPage, userCurrentBusinessDetails?.id, enqueueSnackbar]);

  useEffect(() => {
    fetchScanHistory();
  }, [fetchScanHistory]);

  const handleChangeRowsPerPage = async (event) => {
    setRowsPerPage(event.target.value);
    setPage(1);
  };

  const handleChangePage = async (event, value) => {
    setPage(value + 1);
  };

  if (loading) {
    return <FuseLoading />;
  }

  return (
    <div className="p-28">
      <Typography className="font-bold text-20 mb-24">
        {t('businessCompetitor.scanHistory')}
      </Typography>
      <div className="bg-white p-24 rounded">
        {gridHistoryData?.length > 0 ? (
          <div className="w-full grid md:grid-cols-3 sm:grid-cols-2 justify-center col-span-1 gap-24 rounded">
            {gridHistoryData?.map((value, index) => {
              return (
                <div key={index} className="bg-darkgreen-100 rounded-md sm:w-320 p-16 text-center">
                  <Typography className="font-bold text-16">
                    {t('businessCompetitor.keyword')} :{' '}
                    {capitalizeFirstLetter(value?.queryParams?.keyword)}
                  </Typography>
                  <Typography className="my-4 text-14 font-semibold">
                    {t('businessCompetitor.grid')} {value?.queryParams?.grid_size} *{' '}
                    {value?.queryParams?.grid_size}
                  </Typography>
                  <Typography className="my-4 text-14 font-semibold">
                    {t('businessCompetitor.radius')}: {value?.queryParams?.radius} Kms
                  </Typography>
                  <div className="flex justify-center mt-16 h-192 items-center">
                    <div>
                      {[...Array(value?.queryParams?.grid_size)].map((_, rowIndex) => (
                        <div key={rowIndex} className="flex items-center gap-8 mb-8 last:mb-0">
                          {[...Array(value?.queryParams?.grid_size)].map((item, colIndex) => (
                            <div
                              key={colIndex}
                              className={`bg-red ${
                                value?.queryParams?.grid_size === 3
                                  ? 'w-24 h-24'
                                  : value?.queryParams?.grid_size === 5
                                  ? 'w-16 h-16'
                                  : value?.queryParams?.grid_size === 7
                                  ? 'w-12 h-12'
                                  : 'w-8 h-8'
                              }`}
                            />
                          ))}
                        </div>
                      ))}
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
        ) : (
          <Typography className="text-center font-semibold text-20 py-360">
            {t('businessCompetitor.noScanHistoryData')}
          </Typography>
        )}
      </div>
      {gridHistoryData?.length > 0 && (
        <Pagination
          totalCount={totalCount}
          rowsPerPage={rowsPerPage}
          page={page}
          handleChangePage={handleChangePage}
          handleChangeRowsPerPage={handleChangeRowsPerPage}
        />
      )}
    </div>
  );
};

export default ScanHistory;
